import { RechargeTypeEnum } from '@/pages/funds/topUp/useStateHooks';
import TopUpOrderSecondInformation from '@/pages/funds/topUpOrder/components/TopUpOrderSecondInformation';
import RenderUtil from '@/utils/RenderUtil';
import useUrlState from '@ahooksjs/use-url-state';
import { useTranslation } from 'react-i18next';

/**
 * 充值信息页面
 * @param props IProps 包含以下属性：
 * - changeStep: (step: number) => void 更改步骤的函数
 * - rechargeInformation: any 充值信息
 * - confirmPayment: () => void 确认支付的函数
 * - info: string 当前输入的信息
 * - setInfo: (value: string) => void 设置输入信息的函数
 * @returns 返回JSX元素，展示充值信息和操作按钮
 */
export default ({
                  rechargeInformation,
                  info,
                  confirmPayment,
                  setInfo,
                }: IProps) => {
  const { t } = useTranslation(); // 使用国际化库，便于多语言支持
  const [urlState] = useUrlState<any>({});
  const { rechargeType } = urlState || {};
  return (
    <>
      <div className="overflow-hidden">
        <div className="px-4">
          {/* 充值详情 */}
          <div className="rounded-md  px-3 py-3 border-1 border-backgroundAuxiliaryColor mt-4">
            {
              rechargeType !== RechargeTypeEnum.C2C_RECHARGE &&
              <>
                <div className="text-backContrastColor text-base mb-4">
                  <span className="text-success mr-1">{t('充值')}</span>
                  <span>({rechargeInformation?.coinName})</span>
                </div>
                <div className="text-backContrastColor mb-4 flex justify-between items-center">
                  <div className="flex items-center text-auxiliaryTextColor">
                    <span className="mr-2">{t('充值金额')}</span>
                  </div>
                  <span className="text-base">
                    {RenderUtil.FormatAmount(rechargeInformation?.rechargeAmount)}
                      {rechargeType === RechargeTypeEnum.VIRTUAL_COIN_UPLOAD_FILE &&
                      ' ' + rechargeInformation?.coinName}
                   </span>
                </div>
              </>
            }
            {
              rechargeType === RechargeTypeEnum.C2C_RECHARGE &&
              <>
                <div className="text-backContrastColor text-base mb-4">
                  <span className="text-success mr-1">{t('充值')}</span>
                </div>
                <div className="text-backContrastColor mb-4 flex justify-between items-center">
                  <div className="flex items-center text-auxiliaryTextColor">
                    <span className="mr-2">{t('充值金额')}</span>
                  </div>
                  <span className="text-base">
                    {RenderUtil.FormatAmount(rechargeInformation?.rechargeAmount)}
                    {' ' + rechargeInformation?.coinName}
                   </span>
                </div>
                <div className="text-backContrastColor mb-4 flex justify-between items-center">
                  <div className="flex items-center text-auxiliaryTextColor">
                    <span className="mr-2">{t('待付款金额')}</span>
                  </div>
                  <span className="text-base">
                    {RenderUtil.FormatAmount(rechargeInformation?.pyAmount)}
                    {' ' + rechargeInformation?.payCoinName}
                   </span>
                </div>
              </>
            }
            {rechargeType === RechargeTypeEnum.VIRTUAL_COIN_UPLOAD_FILE && (
              <div className="text-backContrastColor mb-4 flex justify-between items-center">
                <div className="flex items-center text-auxiliaryTextColor">
                  <span className="mr-2">{t('网络')}</span>
                </div>
                <div className="text-base flex items-center gap-1">
                  <img
                    src={rechargeInformation?.virtualNetworkIcon}
                    className="w-6 h-6 rounded-full"
                    alt=""
                  />
                  <span>{rechargeInformation?.virtualNetworkName}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="px-4 pt-5 flex flex-col gap-3">
          {/* 交易方式选择 */}
          <div className="flex flex-col gap-2">
            <div className="text-auxiliaryTextColor"> {t('交易方式')}</div>
            {/*普通通道重置*/}
            <div className="text-backContrastColor flex items-center  ">
              <span className="h-[10px] w-[2px] bg-primary mr-2"></span>
              <span className="text-sm ">
                {rechargeType === RechargeTypeEnum.VIRTUAL_COIN_UPLOAD_FILE &&
                  t('虚拟币充值')}{' '}
                {[RechargeTypeEnum.BANK_CARD_UPLOAD_FILE, RechargeTypeEnum.C2C_RECHARGE].includes(rechargeType) &&
                  t('银行卡充值')}
              </span>
            </div>
          </div>
          {/* C2C第二信息栏，展示和编辑支付详情 */}
          <TopUpOrderSecondInformation
            rechargeInformation={rechargeInformation}
            confirmPayment={confirmPayment}
            info={info}
            setInfo={setInfo}
          />
        </div>
      </div>
    </>
  );
};

interface IProps {
  changeStep: (step: number) => void; // 更改步骤的函数
  rechargeInformation: any; // 充值信息
  confirmPayment: () => void; // 确认支付的函数
  info: string; // 当前输入的信息
  setInfo: (value: string) => void; // 设置输入信息的函数
}
