import { LineMdCoffeeLoop } from '@/assets/icons/mine/LineMdCoffeeLoop';
import PageEnum from '@/enums/pageEnum';
import { history } from '@@/core/history';
import { Button } from '@nextui-org/react';
import { useTranslation } from 'react-i18next';
import { useModel } from '@@/exports';
import useUrlState from '@ahooksjs/use-url-state';
import { RechargeTypeEnum } from '@/pages/funds/topUp/useStateHooks';
import CacheEnum from '@/enums/cacheEnum';
import { CustomerServiceTypeEnum } from '@/enums/businessEnum';

/**
 * 该函数是一个无参数的函数组件，用于渲染一个包含充值确认信息的页面。
 * 页面顶部显示一个正在审核的信息，底部提供返回和联系客服的按钮。
 *
 * @returns 返回渲染的React元素。
 */
export default ({rechargeInformation}:any) => {
  // 使用useTranslation钩子来获取翻译函数
  const { t } = useTranslation();
  const { gotoC2CCustomerService } = useModel('imSocket');
  const [urlState] = useUrlState<any>({});
  const { rechargeType } = urlState || {};

  return (
    <div className="h-[80vh] sm:h-[70vh] relative">
      {/* 充值确认顶部导航 */}
      <div className="px-4 mt-16">
        <div className="flex flex-col items-center justify-center  h-[200px]  top-[30%]">
          <LineMdCoffeeLoop
            width="50px"
            height="50px"
            className="text-primary"
          />
          <div className="text-xl text-backContrastColor my-4">
            {t('审核中')}
          </div>
          <div className="text-auxiliaryTextColor px-4">
            {t('已完成充值相关信息提交，工作人员会尽快审核，请耐心等待')}
          </div>
        </div>
        <div className="flex items-center absolute  bottom-4 bg-background left-0 px-4 w-full">
          <Button
            className="mainColorButton flex-1 !bg-backgroundAuxiliaryColor !text-foreground mr-1"
            onClick={() => {
              if (+rechargeType === +RechargeTypeEnum.C2C_RECHARGE) {
                history.go(-1);
                localStorage.removeItem(CacheEnum.APP_C2C_ORDER_STEPS);
                return;
              }
              history.replace(PageEnum.TopUp);
            }}
          >
            {t('返回')}
          </Button>
          <Button
            className="mainColorButton flex-2 mx-2"
            onClick={() => {
              gotoC2CCustomerService({
                groupType: rechargeType,
                channelId: rechargeInformation?.id,
                rechargeType:rechargeType
              });
            }}
          >
            {' '}
            {t('联系客服')}
          </Button>
        </div>
      </div>
    </div>
  );
};
