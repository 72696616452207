import { FluentDiamond24Filled } from '@/assets/icons/comm/FluentDiamond24Filled';
import { IconParkSolidCorrect } from '@/assets/icons/comm/IconParkSolidCorrect';
import { BaseStepperProps } from '@/components/base/baseStepper/index';

export default ({ steps, value, Thumb }: BaseStepperProps) => {
  return (
    <div className="w-full  overflow-hidden">
      <div className="w-full flex items-center justify-between">
        {steps.map((item, index) => {
          return (
            <div className="relative" key={index}>
              <div
                className={`absolute left-0 top-1/2 z-10 -translate-y-1/2 w-[100vw] h-[2px] bg-backgroundAuxiliaryColor ${
                  index < value ? 'bg-backContrastColor' : ''
                }`}
              ></div>
              <div
                className={`relative z-50 w-[30px] h-[30px] rounded-full flex-shrink-0 bg-background`}
              >
                {/*展示指定步骤图像*/}
                {Thumb && (
                  <Thumb
                    width="30px"
                    height="30px"
                    className={`absolute left-0 top-0 z-0 w-[30px] h-[30px] rounded-full flex-shrink-0 text-backgroundAuxiliaryColor ${
                      index <= value ? '!text-backContrastColor' : ''
                    }`}
                  />
                )}
                {/*展示默认步骤图像*/}
                {!Thumb && (
                  <FluentDiamond24Filled
                    width="30px"
                    height="30px"
                    className={`absolute left-0 top-0 z-0 w-[30px] h-[30px] rounded-full flex-shrink-0 text-backgroundAuxiliaryColor ${
                      index <= value ? '!text-backContrastColor' : ''
                    }`}
                  />
                )}
                {/*已经完成的图形中间显示完成图标*/}
                {index < value && (
                  <span className="absolute top-1/2 left-1/2 z-100 -translate-x-1/2 -translate-y-1/2 text-[#fff] text-[12px]">
                    <IconParkSolidCorrect
                      width="0.8em"
                      height="0.8em"
                      className={`${index <= value ? 'text-background' : ''}`}
                    />
                  </span>
                )}
                {/*未完成的显示标号*/}
                {index >= value && (
                  <span
                    className={`absolute top-1/2 select-none left-1/2 z-100 -translate-x-1/2 -translate-y-1/2 text-[12px] ${
                      index <= value
                        ? 'text-background'
                        : 'text-backContrastColor/40'
                    }`}
                  >
                    {index + 1}
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="w-full flex items-center justify-between pt-1">
        {steps.map((item, index) => {
          return (
            <div key={index}>
              {/*显示步骤文字*/}
              <div
                className={`text-center ${
                  index === value
                    ? 'text-backContrastColor'
                    : 'text-foreground/60'
                }`}
                style={{ whiteSpace: 'nowrap' }}
              >
                {item}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
