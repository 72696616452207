import { PhCopyFill } from '@/assets/icons/mine/PhCopyFill';
import BaseUpload from '@/components/base/baseUpload/index';
import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import { RechargeTypeEnum } from '@/pages/funds/topUp/useStateHooks';
import { FormatUtils } from '@/utils/format';
import { toJsonData } from '@/utils/socket';
import { history } from '@@/core/history';
import useUrlState from '@ahooksjs/use-url-state';
import { Button, Divider } from '@nextui-org/react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

/**
 * 充值确认组件
 * @props rechargeInformation 充值信息，包含银行卡等详情
 * @props confirmPayment 确认付款的函数
 * @props setInfo 设置付款记录截图证明的URL
 * @returns 返回JSX元素，展示充值确认界面
 */
export default ({
                  rechargeInformation,
                  confirmPayment,
                  setInfo,
                  info,
                }: IProps) => {
  const { t } = useTranslation(); // 使用国际化库，便于多语言支持
  const bankCard = toJsonData(rechargeInformation?.bankCard);
  const bankCardNumber = bankCard?.find?.((item: any) => {
    return item?.field === 'bankCardNumber';
  })?.value;
  const cardholder = bankCard?.find?.((item: any) => {
    return item?.field === 'cardholder';
  })?.value;
  const bankName = bankCard?.find?.((item: any) => {
    return item?.field === 'bankName';
  })?.value;

  const [urlState] = useUrlState<any>({});
  const { rechargeType } = urlState || {};
  return (
    <div className="flex flex-col gap-3">
      {[RechargeTypeEnum.BANK_CARD_UPLOAD_FILE, RechargeTypeEnum.C2C_RECHARGE].includes(rechargeType) && (
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <div className="flex justify-between mt-2">
              <span className="text-auxiliaryTextColor">
                {t('持卡人/公司')}
              </span>{' '}
              <PhCopyFill
                onClick={() => FormatUtils.copyText(cardholder)}
                className="text-auxiliaryTextColor cursor-pointer"
              />
            </div>
            <div className="text-backContrastColor  break-words text-sm ">
              {cardholder}{' '}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <span className="text-auxiliaryTextColor">{t('银行名称')}</span>{' '}
              <PhCopyFill
                onClick={() => FormatUtils.copyText(bankName)}
                className="text-auxiliaryTextColor cursor-pointer"
              />
            </div>
            <div className="text-backContrastColor break-words text-sm">
              {bankName} {/* 显示完整的银行卡号 */}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <span className="text-auxiliaryTextColor">{t('银行卡号')}</span>{' '}
              {/* 显示银行卡号 */}
              <PhCopyFill
                onClick={() => FormatUtils.copyText(bankCardNumber)}
                className="text-auxiliaryTextColor cursor-pointer"
              />
            </div>
            <div className="text-backContrastColor break-words text-sm">
              {bankCardNumber} {/* 显示完整的银行卡号 */}
            </div>
          </div>
        </div>
      )}
      {rechargeType === RechargeTypeEnum.VIRTUAL_COIN_UPLOAD_FILE && (
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <div className="flex justify-between mt-2">
              <span className="text-auxiliaryTextColor">{t('虚拟地址')}</span>{' '}
              <PhCopyFill
                onClick={() =>
                  FormatUtils.copyText(
                    rechargeInformation?.virtualNetworkAddress,
                  )
                }
                className="text-auxiliaryTextColor cursor-pointer"
              />
            </div>
            <div className="text-backContrastColor  break-words text-sm flex justify-start">
              {rechargeInformation?.virtualNetworkAddress}
            </div>
          </div>
        </div>
      )}
      {rechargeType === RechargeTypeEnum.VIRTUAL_COIN_UPLOAD_FILE && (
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-2">
            <div className="flex justify-between mt-2">
              <span className="text-auxiliaryTextColor">{t('虚拟地址')}</span>{' '}
            </div>
            <div className="text-backContrastColor  break-words text-sm flex justify-center">
              <QRCode
                value={rechargeInformation?.virtualNetworkAddress}
                style={{ width: '150px', height: '150px' }}
              />
            </div>
          </div>
        </div>
      )}

      <Divider className="my-4 !bg-backgroundAuxiliaryColor" />

      <div className="mt-4 mb-1">
        <span className="text-auxiliaryTextColor">{t('付款记录截图证明')}</span>{' '}
        {/* 显示付款记录截图证明的提示 */}
      </div>
      <div className="bg-backgroundAuxiliaryColor  w-full">
        <BaseUpload
          currentUrls={info}
          onChange={(imgUrl: any) => {
            setInfo(imgUrl as string); // 当上传成功时，设置截图证明的URL
          }}
        >
          <div
            className="bg-backgroundAuxiliaryColor  h-[140px]
              w-full flex flex-col justify-center items-center"
          >
            <span className="text-[12px] text-auxiliaryTextColor">
              {' '}
              {t('请上传付款记录截图证明')}
            </span>
          </div>
        </BaseUpload>
      </div>
      <div className="flex items-center  bg-background  w-full mt-7">
        <Button
          className="mainColorButton flex-1 !bg-backgroundAuxiliaryColor !text-foreground mr-1"
          onClick={() => {
            if (+rechargeType === +RechargeTypeEnum.C2C_RECHARGE) {
              history.go(-1);
              localStorage.removeItem(CacheEnum.APP_C2C_ORDER_STEPS);
              return;
            }
            localStorage.removeItem(CacheEnum.APP_C2C_ORDER_STEPS);
            history.replace(PageEnum.TopUp); // 取消订单，清除缓存并跳转回C2C页面
          }}
        >
          {t('取消订单')}
        </Button>
        <Button
          className="mainColorButton flex-2 ml-1"
          onClick={confirmPayment} // 点击确认付款，执行确认付款操作
        >
          {t('确认付款')}{' '}
        </Button>
      </div>
    </div>
  );
};

interface IProps {
  rechargeInformation: any; // 充值信息，结构较复杂，包含银行卡等详情
  confirmPayment: () => void; // 确认付款的函数
  info: string; // 付款记录截图证明的URL
  setInfo: (value: string) => void; // 设置付款记录截图证明的URL的函数
}
