import HorizontalStepBar from '@/components/base/baseStepper/HorizontalStepBar';
import VerticalStepBar from '@/components/base/baseStepper/VerticalStepBar';
import { ReactNode } from 'react';

/**
 * @component 步骤条
 * @param{('horizontal'|'vertical')} [direction='horizontal'] - 步骤条方向，可选值为 'horizontal'（水平）或 'vertical'（垂直），默认为 'horizontal',
 * 如果是水平方向则必须父盒子有具体的高度,如果是垂直方向则必须父盒子有具体的宽度
 * @param{string[]|React.ReactNode[]} steps - 步骤展示内容，可以是字符串数组或 ReactNode 数组，表示每个步骤的标题或内容
 * @param{number} value - 当前步骤，应为一个非负整数，表示当前处于步骤序列中的哪个位置
 * @param{React.ComponentType<any>} [Thumb] - 自定义步骤图标组件，用于渲染每个步骤的图形，如果不指定，则使用默认图标。传入组件应接受并处理任何所需的属性
 */
export default function BaseStepper({
  direction = 'horizontal',
  ...props
}: BaseStepperProps) {
  return (
    <>
      {/*横向步骤条*/}
      {direction === 'horizontal' && (
        <HorizontalStepBar {...props} direction={direction} />
      )}
      {/*纵向步骤条*/}
      {direction === 'vertical' && (
        <VerticalStepBar {...props} direction={direction} />
      )}
    </>
  );
}

export interface BaseStepperProps {
  //步骤展示内容
  steps: string[] | ReactNode[];
  //当前步骤
  value: number;
  //渲染步骤条的图形
  Thumb?: any;
  //横折或者纵向
  direction?: 'horizontal' | 'vertical';
}
