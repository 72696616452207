import BaseStepper from '@/components/base/baseStepper';
import TopUpOrderFirst from '@/pages/funds/topUpOrder/components/TopUpOrderFirst';
import TopUpOrderSecondly from '@/pages/funds/topUpOrder/components/TopUpOrderSecondly';
import {useTranslation} from 'react-i18next';
import useStateHooks from './useStateHooks';

/**
 * 充值进度页面
 */
export default () => {
  const {
    step, // 当前步骤
    changeStep, // 切换步骤的方法
    rechargeInformation, // 充值信息
    confirmPayment, // 确认支付方法
    info, // 付款凭证信息
    setInfo, // 设置付款凭证信息的方法
  } = useStateHooks();
  const { t } = useTranslation();
  return (
    <div className="min-h-[100vh] pt-4 ">
      <div className="px-6">
        <BaseStepper steps={[t('订单已生成'), t('审核')]} value={step} />
      </div>
      {+step === 0 && (
        <TopUpOrderFirst
          changeStep={changeStep}
          rechargeInformation={rechargeInformation}
          info={info as any}
          setInfo={setInfo as any}
          confirmPayment={confirmPayment}
        />
      )}
      {+step === 1 && <TopUpOrderSecondly   rechargeInformation={rechargeInformation} />}
    </div>
  );
};
