import type { SVGProps } from 'react';

export function FluentDiamond24Filled(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M2.659 13.593a2.25 2.25 0 0 1 0-3.182l7.752-7.752a2.25 2.25 0 0 1 3.182 0l7.751 7.752a2.25 2.25 0 0 1 0 3.182l-7.751 7.751a2.25 2.25 0 0 1-3.182 0z"
      ></path>
    </svg>
  );
}
