import CacheEnum from '@/enums/cacheEnum';
import { useLocalStorage } from '@/hooks/useStorage';
import { rechargeFiatCurrencyApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import useUrlState from '@ahooksjs/use-url-state';
import { useMount } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';
import { RechargeTypeEnum } from '@/pages/funds/topUp/useStateHooks';
import { useModel } from "@umijs/max"
/**
 * 默认导出一个函数组件，用于管理充值流程中的状态和逻辑。
 * 组件内部处理了URL状态、本地存储、翻译、步骤切换、以及发起充值请求等功能。
 *
 * @returns 返回一个对象，包含当前步骤、改变步骤的方法、充值信息、验证码、确认支付方法等。
 */
export default () => {
  // 使用useUrlState钩子来获取URL中的状态信息
  const [urlState] = useUrlState<any>({});
  // 解构URL状态中的id和code
  const { id, code, rechargeType } = urlState || {};
  // 使用useTranslation钩子来获取翻译函数
  const { t } = useTranslation();
  // 使用useState钩子来管理充值信息的状态
  const [rechargeInformation, setRechargeInformation] = useState<any>();
  // 使用useLocalStorage钩子来管理当前步骤的状态，并设置默认值为0
  const [step, setStep] = useLocalStorage({
    key: CacheEnum.APP_C2C_ORDER_STEPS,
    defaultValue: 0,
  });
  // 跳转到客服提示
  const { gotoCustomerService } = useModel('imSocket');
  
  // 在组件挂载时，从本地存储中读取充值信息
  useMount(() => {
    if (+rechargeType === +RechargeTypeEnum.C2C_RECHARGE) {
      setStep(0);
    }
    setRechargeInformation(
      JSON.parse(
        localStorage.getItem(CacheEnum.APP_RECHARGE_INFORMATION) as any,
      ),
    );
  });
  // 定义切换步骤的函数
  const changeStep = (step: number) => {
    setStep(step);
  };

  // 使用useState钩子来管理付款凭证的信息
  const [info, setInfo] = useState();
  // 使用useReq钩子来发起充值法币的请求，设置为手动触发，成功时显示成功提示并切换到下一步
  const { run: rechargeFiatCurrencyReq } = useReq(rechargeFiatCurrencyApi, {
    manual: true,
    onSuccess() {
      toast.success(t('提交成功'));
      changeStep(1);
      // const amount=+rechargeType === +RechargeTypeEnum.C2C_RECHARGE ? rechargeInformation?.pyAmount : rechargeInformation?.rechargeAmount
      // // 跳转客服 coinName
      // gotoCustomerService(
      //   '?msg=' + t('我想要充值') + amount + rechargeInformation?.payCoinName,
      // )
    },
  });
  // 定义确认支付的函数，内部会验证付款凭证信息是否存在，然后发起充值请求
  const confirmPayment = () => {
    setTimeout(() => {
      if (!info) {
        return toast.error(t('请上传付款凭证'));
      }
      rechargeFiatCurrencyReq({
        coinId: +rechargeType === +RechargeTypeEnum.C2C_RECHARGE ? rechargeInformation?.pyAmount :  rechargeInformation?.payCoinId,
        walletType: 2,
        amount: +rechargeType === +RechargeTypeEnum.C2C_RECHARGE ? rechargeInformation?.pyAmount : rechargeInformation?.rechargeAmount,
        channelId: rechargeInformation?.channelId,
        payGatewayPassword: rechargeInformation?.transactionPassword,
        // cache: rechargeInformation?.cache,
        info,
        id,
      });
    });
  };
  // 返回相关状态和函数供父组件使用
  return {
    step, // 当前步骤
    changeStep, // 切换步骤的方法
    rechargeInformation, // 充值信息
    code, // 验证码
    confirmPayment, // 确认支付方法
    info, // 付款凭证信息
    setInfo, // 设置付款凭证信息的方法
  };
};
