import CacheEnum from '@/enums/cacheEnum';
import PageEnum from '@/enums/pageEnum';
import {
  getBankRechargeChannelApi,
  getCarouselImageApi,
  getRechargeVirtualCurrencyChannelApi,
  getUdunRechargeAddressApi,
  verifyChannelPasswordApi,
} from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { history, useModel } from '@umijs/max';
import { useMount } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@/layouts/components/ToastMessage';
import { CustomerServiceTypeEnum } from '@/enums/businessEnum';
import { toJsonData } from '@/utils/socket';

// 充值类型管理--api充值和上传截图充值
export enum RechargeTypeEnum {
  /**
   *  银行卡充值-上传截图
   * */
  BANK_CARD_UPLOAD_FILE = '0',
  /**
   *  虚拟币-自动回调充值
   * */
  VIRTUAL_COIN_CALLBACK = '1',
  /**
   *  虚拟币-上传截图充值
   * */
  VIRTUAL_COIN_UPLOAD_FILE = '4',
  /**
   *  c2c
   * */
  C2C_RECHARGE = '3',
}

// 充值方式
export enum RechargePartyMethodEnum {
  // 虚拟币充值
  VIRTUAL_CURRENCY_RECHARGE = '2',
  // 银行卡充值
  BANK_CARD_RECHARGE = '1',
  // C2C充值
  C2C_RECHARGE = '3',
}

// 充值方式-卡片可控类型
export enum RechargePartyMethodCardControlEnum {
  // 虚拟币充值
  VIRTUAL_CURRENCY = 'VIRTUAL_CURRENCY',
  // 银行卡充值
  BANK_CARD = 'BANK_CARD'
}

export default function useStateHooks() {
  const { t } = useTranslation();
  const { appInfo } = useModel('appInfo');
  // 可充值方式
  const RECHARGEABLE_METHODS = useMemo(() => {
    return toJsonData(appInfo?.['RECHARGEABLE_METHODS']) || [];
  }, [appInfo]);

  // 禁止有未完成充值订单又开始新的充值，除非点击取消
  useMount(() => {
    // 逻辑判断后重定向到C2C订单页面
    if (
      localStorage.getItem(CacheEnum.APP_C2C_ORDER_STEPS) &&
      Number(localStorage.getItem(CacheEnum.APP_C2C_ORDER_STEPS)) === 0
    ) {
      history.push(PageEnum.TopUp_Order);
      return;
    }
  });
  // 快捷充值数量状态管理
  const [currentQuantity, setCurrentQuantity] = useState<number>();
  //充值方式
  const [RechargeMethod, setRechargeMethod] = useState([
    {
      text: t('虚拟币充值'),
      value: RechargePartyMethodEnum.VIRTUAL_CURRENCY_RECHARGE,
      cardControl: RechargePartyMethodCardControlEnum.VIRTUAL_CURRENCY,
    },
    {
      text: t('银行卡充值'),
      value: RechargePartyMethodEnum.BANK_CARD_RECHARGE,
      cardControl: RechargePartyMethodCardControlEnum.BANK_CARD,
    },
  ]);

  //当前充值方式
  const [currentRechargeMethod, setCurrentRechargeMethod] = useState(
    RechargePartyMethodEnum.VIRTUAL_CURRENCY_RECHARGE,
  );
  useEffect(() => {
    if (!RECHARGEABLE_METHODS || RECHARGEABLE_METHODS?.length === 0) return;
    setRechargeMethod(prevState => {
      return prevState?.filter(item => RECHARGEABLE_METHODS?.includes(item.cardControl));
    });
    setCurrentRechargeMethod(RechargeMethod?.find(item => RECHARGEABLE_METHODS?.includes(item.cardControl))?.value as any)
  }, [RECHARGEABLE_METHODS]);
  //-----------------------虚拟币充值------------------------------------
  // 获取通道
  const {
    run: getRechargeVirtualCurrencyChannelReq,
    data: rechargeVirtualCurrencyChannel = [] as any,
    loading: loadingRechargeVirtualCurrencyChannel,
  } = useReq(getRechargeVirtualCurrencyChannelApi, {
    manual: true,
    formatResult(res: any) {
      return res.data ?? [];
    },
  });
  useMount(() => {
    getRechargeVirtualCurrencyChannelReq();
  });
  //虚拟币充值展示币种--根据通道拿到币种列表，根据coinId去重;并且把列表中的第一个作为默认提现币种
  const [currentVirtualShowCoin, setCurrentVirtualShowCoin] = useState<{
    coinId: string;
    coinName: string;
    coinIcon: string;
  }>();
  const virtualShowCoin = useMemo(() => {
    if (rechargeVirtualCurrencyChannel?.length === 0) return [];
    // 筛选后的列表
    const res = rechargeVirtualCurrencyChannel.reduce((acc: any, cur: any) => {
      const exist = acc.find((item: any) => item.coinId === cur.coinId);
      if (!exist) {
        acc.push({
          coinName: cur?.coinName,
          coinId: cur?.coinId,
          coinIcon: cur?.coinIcon,
        });
      }
      return acc;
    }, []);
    //设置默认选中币种
    setCurrentVirtualShowCoin(res?.[0]);
    //返回展示结果
    return res;
  }, [rechargeVirtualCurrencyChannel]);
  //展示通道--根据当前选中的币种id和rechargeVirtualCurrencyChannel筛选出展示通道
  const [currentVirtualChannel, setCurrentVirtualChannel] = useState<any>({});
  const virtualShowChannel = useMemo(() => {
    if (rechargeVirtualCurrencyChannel?.length === 0 || !currentVirtualShowCoin)
      return [];
    const res = rechargeVirtualCurrencyChannel.filter(
      (item: any) => item.coinId === currentVirtualShowCoin?.coinId,
    );
    if (res.length === 0) return [];
    setCurrentVirtualChannel(res?.[0]);
    return res;
  }, [rechargeVirtualCurrencyChannel, currentVirtualShowCoin]);
  //-----------------------银行卡充值------------------------------------
  // 获取通道
  const {
    run: getBankRechargeChannelReq,
    data: bankRechargeChannel = [] as any,
    loading: loadingBankRechargeChannel,
  } = useReq(getBankRechargeChannelApi, {
    manual: true,
    formatResult(res: any) {
      return res.data ?? [];
    },
  });
  useMount(() => {
    getBankRechargeChannelReq();
  });
  //银行卡充值展示币种--根据通道拿到币种列表，根据coinId去重;并且把列表中的第一个作为默认提现币种
  const [currentBankShowCoin, setCurrentBankShowCoin] = useState<{
    coinId: string;
    coinName: string;
    coinIcon: string;
  }>();
  const bankShowCoin = useMemo(() => {
    if (bankRechargeChannel?.length === 0) return [];
    // 筛选后的列表
    const res = bankRechargeChannel.reduce((acc: any, cur: any) => {
      const exist = acc.find((item: any) => item.coinId === cur.coinId);
      if (!exist) {
        acc.push({
          coinName: cur?.coinName,
          coinId: cur?.coinId,
          coinIcon: cur?.coinIcon,
        });
      }
      return acc;
    }, []);
    //设置默认选中币种
    setCurrentBankShowCoin(res?.[0]);
    //返回展示结果
    return res;
  }, [bankRechargeChannel]);
  // 展示通道--根据当前选中的币种id和rechargeVirtualCurrencyChannel筛选出展示通道
  const [currentBankChannel, setCurrentBankChannel] = useState<any>({});
  const bankShowChannel = useMemo(() => {
    if (bankRechargeChannel?.length === 0 || !currentBankShowCoin) return [];
    const res = bankRechargeChannel.filter(
      (item: any) => item.coinId === currentBankShowCoin?.coinId,
    );
    if (res.length === 0) return [];
    setCurrentBankChannel(res?.[0]);
    return res;
  }, [bankRechargeChannel, currentBankShowCoin]);
  //-----------------------充值公共状态和方法------------------------------------
  // 提交表单的状态管理
  const [submitForm, setSubmitForm] = useState({
    rechargeAmount: '',
    transactionPassword: '',
    coinId: '',
    walletType: 3,
  });
  // 处理提交表单中的值变化
  const onChangeSubmitForm = (value: any, type: keyof typeof submitForm) => {
    setSubmitForm({
      ...submitForm,
      [type]: value,
    });
  };
  // 获取用户Udun充值地址
  const { runAsync: getUdunRechargeAddressReq } = useReq(
    getUdunRechargeAddressApi,
    {
      manual: true,
    },
  );
  // 根据充值类型的不同获取当前通道
  const currentChannel = useMemo(() => {
    if (
      currentRechargeMethod ===
      RechargePartyMethodEnum.VIRTUAL_CURRENCY_RECHARGE
    )
      return currentVirtualChannel;
    if (currentRechargeMethod === RechargePartyMethodEnum.BANK_CARD_RECHARGE)
      return currentBankChannel;
  }, [currentBankChannel, currentVirtualChannel, currentRechargeMethod]);
  // 根据充值类型的不同获取当前选择币种
  const currency = useMemo(() => {
    if (
      currentRechargeMethod ===
      RechargePartyMethodEnum.VIRTUAL_CURRENCY_RECHARGE
    )
      return currentVirtualShowCoin;
    if (currentRechargeMethod === RechargePartyMethodEnum.BANK_CARD_RECHARGE)
      return currentBankShowCoin;
  }, [currentBankChannel, currentVirtualChannel, currentRechargeMethod]);

  // 数据填充到本地缓存，然后按条件跳转页面
  const savaDataAndJump = async () => {
    // 上传截图充值
    if (
      [
        RechargeTypeEnum.BANK_CARD_UPLOAD_FILE,
        RechargeTypeEnum.VIRTUAL_COIN_UPLOAD_FILE,
      ].includes(currentChannel?.rechargeType?.toString())
    ) {
      localStorage.setItem(CacheEnum.APP_C2C_ORDER_STEPS, '0');
      localStorage.setItem(
        CacheEnum.APP_RECHARGE_INFORMATION,
        JSON.stringify({
          ...submitForm,
          ...{
            ...currentChannel,
            channelId: currentChannel?.id,
          },
          // cache: data,
          ...{
            ...(currency as any),
            coinId: (currency as any)?.id,
          },
        }),
      );
      setTimeout(() => {
        history.push(
          `${PageEnum.TopUp_Order}?rechargeType=${currentChannel?.rechargeType}`,
        );
      });
    } else {
      //三方充值--直接跳转显示二维码
      const udunAddress = (await getUdunRechargeAddressReq({
        channelId: currentChannel.id,
      })) as any;
      if (udunAddress?.code === 'Response_Error') return;
      localStorage.setItem(
        CacheEnum.APP_RECHARGE_INFORMATION,
        JSON.stringify({
          ...submitForm,
          udunAddress,
          ...{
            ...currentChannel,
            channelId: currentChannel?.id,
          },
          // cache: data,
          ...{
            ...(currency as any),
            coinId: (currency as any)?.id,
          },
        }),
      );
      setTimeout(() => {
        history.push(PageEnum.THIRD_PARTY_RECHARGE);
      });
    }
  };
  // 验证通道密码的逻辑处理和数据提交
  const { runAsync: verifyChannelPasswordReq, loading: submitLoading } = useReq(
    verifyChannelPasswordApi,
    {
      manual: true,
      loadingDefault: false,
      async onSuccess(data) {
        // 验证成功后的逻辑处理，包括数据缓存和页面重定向
        if (data) {
          await savaDataAndJump();
        } else {
          toast.error(t('密码错误'));
        }
      },
    },
  );

  // 跳转到客服提示
  const { gotoC2CCustomerService } = useModel('imSocket');

  // 充值按钮点击事件处理，进行表单验证和密码验证
  const topUp = async () => {
    // 普通充值验证
    if (
      [
        RechargeTypeEnum.BANK_CARD_UPLOAD_FILE,
        RechargeTypeEnum.VIRTUAL_COIN_UPLOAD_FILE,
      ].includes(currentChannel?.rechargeType?.toString())
    ) {
      const max = currentChannel?.maxAmount;
      const min = currentChannel?.minAmount;
      if (!(submitForm.rechargeAmount && +submitForm.rechargeAmount !== 0))
        return toast.error(t('充值金额不能为0'));
      if (+submitForm.rechargeAmount < +min) {
        toast.warning(t('充值金额不能低于') + min);
        return;
      }
      if (+submitForm.rechargeAmount > +max) {
        toast.warning(t('充值金额不能超过') + max);
        return;
      }

      if (
        RechargeTypeEnum.BANK_CARD_UPLOAD_FILE ===
        currentChannel?.rechargeType?.toString()
      ) {
        gotoC2CCustomerService({
          msg: t('我想要充值') + ' ' + submitForm.rechargeAmount + ' ' + currentBankShowCoin?.coinName,
          groupType: currentChannel.rechargeType,
          channelId: currentChannel?.id,
          rechargeType: currentChannel?.rechargeType,
        });
        return;
      }
    }
    // 校验是否选择充值通道
    if (!currentChannel) return toast.error(t('请选择交易通道'));

    // 调用验证密码的接口
    await verifyChannelPasswordReq({
      channelId: currentChannel.id,
      password: submitForm.transactionPassword,
    });
  };

  //-----------------------轮播图------------------------------------
  // 使用自定义钩子获取轮播图数据
  const {
    data: carousel = [] as any,
    run: getCarouselImage,
    loading: loadingCarousel,
  } = useReq(getCarouselImageApi, {
    manual: true,
    defaultValue: [],
    formatResult(res: any) {
      return res.data ?? [];
    },
  });
  useMount(() => {
    getCarouselImage({ purpose: 3 });
  });

  // 返回所有状态和函数供组件使用
  return {
    carousel,
    submitForm,
    onChangeSubmitForm,
    currentQuantity,
    setCurrentQuantity,
    topUp,
    submitLoading,
    loadingCarousel,
    RechargeMethod,
    currentRechargeMethod,
    setCurrentRechargeMethod,
    currentVirtualShowCoin,
    setCurrentVirtualShowCoin,
    virtualShowCoin,
    virtualShowChannel,
    currentVirtualChannel,
    setCurrentVirtualChannel,
    loadingRechargeVirtualCurrencyChannel,
    currentBankShowCoin,
    setCurrentBankShowCoin,
    bankShowCoin,
    currentBankChannel,
    setCurrentBankChannel,
    loadingBankRechargeChannel,
    bankShowChannel,
  };
}
// 导出类型定义，方便其他组件使用
export type topUpType = ReturnType<typeof useStateHooks>;
