import i18n from '@/locales/index';
import copy from 'copy-to-clipboard';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { toast } from '@/layouts/components/ToastMessage';

dayjs.extend(relativeTime);
const { t } = i18n;

export class FormatUtils {
  /**
   * @description 复制指定文本到剪切板，并根据操作结果显示成功或失败的提示信息。
   * @param text 需要复制的文本内容。
   */
  static copyText(text: string) {
    const searchParams = decodeURI(text);
    copy(searchParams);
    searchParams ? toast.success(t('复制成功')) : toast.error(t('复制失败'));
  }

  // 获取币种精度
  static getPrecision(coin: any, coinPrecisionData?: Record<string, number>) {
    // 如果传入了精度数据，则使用传入的数据
    if (coinPrecisionData && Object.keys(coinPrecisionData).length) {
      return coinPrecisionData[coin] ?? 8;
    }
    // 默认返回8位精度
    return 8;
  }

  /**
   * @description 格式化数字，保留到最大指定小数位。
   * @param value 需要格式化的值。
   * @param max 保留的最大小数位数，默认为6。
   */
  static processDecimal(value: any = 0, max: any = 6) {
    try {
      const num = parseFloat(value);
      if (isNaN(num)) {
        return 0;
      }
      // 注意toFixed方法可能会引入精度问题
      return parseFloat(num.toFixed(max));
    } finally {
    }
  }

  // 删除localStorage中的所有数据除了指定的key
  static clearLocalStorage(key: string) {
    const keys = Object.keys(localStorage);
    keys?.forEach((item) => {
      if (item !== key && item !== 'theme') {
        localStorage.removeItem(item);
      }
    });
  }

  /**
   * @description 对数组进行稳定排序，即保持相等元素的初始顺序。
   * @param array 需要排序的数组。
   * @param comparator 比较函数，用于定义排序的方式。
   */
  static stableSort(array: any[], comparator: (a: any, b: any) => number) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const sort = comparator(a[0], b[0]);
      if (sort !== 0) {
        return sort;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  /**
   * @description 创建一个降序比较器，用于数组排序。
   * @param a 比较的第一个元素。
   * @param b 比较的第二个元素。
   * @param sortId 排序依据的属性。
   */
  static descendingComparator(a: any, b: any, sortId: any) {
    if (b?.[sortId] < a?.[sortId]) {
      return -1;
    }
    if (b?.[sortId] > a?.[sortId]) {
      return 1;
    }
    return 0;
  }

  /**
   * @description 根据排序类型和ID获取相应的比较器函数。
   * @param sort 排序的方向（升序或降序）。
   * @param sortId 排序依据的属性。
   */
  static getComparator(sort: any, sortId: any) {
    // 根据sort和sortId返回对应的比较器函数
    // 实现的逻辑可能类似于以下示例
    switch (sortId) {
      case 'title':
        return (a: any, b: any) => a.field1 - b.field1;
      case 'p':
        return (a: any, b: any) => a.field2 - b.field2;
      // 其他字段的比较器
      default:
        return (a: any, b: any) => 0;
    }
  }

  /**
   * @description 格式化时间戳为相对时间描述，如“3小时前”。
   * @param timestamp 需要格式化的时间戳。
   */
  static formatTimestamp(timestamp: number) {
    const now = dayjs();
    const time = dayjs(timestamp);
    const diff = now.diff(time, 'minute');
    const years = Math.floor(diff / (365 * 24 * 60));
    const days = Math.floor((diff % (365 * 24 * 60)) / (24 * 60));

    const formattedYears = years > 0 ? `${years}${t('年')}` : '';
    const formattedDays = days > 0 ? `${days}${t('天')}` : '';

    let formattedTime = '';
    if (years === 0 && days === 0) {
      const hours = Math.floor((diff % (24 * 60)) / 60);
      formattedTime = `${hours}${t('小时')}`;
    }
    return `${formattedYears} ${formattedDays} ${formattedTime} ${t('前')}`;
  }

  /**
   * @description 对市场列表进行排序。
   * @param marketList 市场列表数组。
   * @param sortId 排序依据的属性。
   * @param sort 排序的方向（升序或降序）。
   */
  static sortMarketList(marketList: any[], sortId: any, sort: any) {
    if (marketList?.length === 0) return [];
    return marketList.sort((a, b) => {
      const valueA = a[sortId];
      const valueB = b[sortId];

      if (valueA < valueB) {
        return sort === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return sort === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  /**
   * @description 从URL中提取数字或预定义的单词。
   * @param url 需要提取数字的URL字符串。
   */

  static extractNumberFromUrl(url: string) {
    // 使用正则表达式从 URL 中提取数字或指定的单词
    const match = url?.match?.(/https?:\/\/(\w+)\./);

    if (match && match[1]) {
      // 检查提取的部分是否为 'pc', 'h5' 或其他预定义的值
      const predefinedValues = ['pc', 'h5']; // 可以在这里添加更多的预定义值
      if (
        predefinedValues.includes(match[1].toLowerCase()) ||
        match[1].length < 4
      ) {
        return '';
      }
      return match[1];
    }
    return ''; // 如果无法匹配，返回空字符串
  }

  /**
   * @description 将科学计数法的数字转换为完整数字字符串。
   * @param num 需要转换的数字。
   */
  static getFullNum(num: any) {
    //处理非数字
    if (isNaN(num)) {
      return num;
    }
    //处理不需要转换的数字
    let str = '' + num;
    if (!/e/i.test(str)) {
      return num;
    }
    return num.toFixed(18).replace(/\.?0+$/, '');
  }
}
